<template>
  <div>
    <div class="addTitImgBox-mb">
      <img src="../../assets/images/cp240201/addtitImg-mb.jpg" alt="">
    </div>
  <div class="m">
    <div class="addTitImgBox">
      <img src="../../assets/images/cp240201/addtitImg-pc.jpg" alt="">
    </div>
    <div class="titImgBox">
      <img src="../../assets/images/cp240201/TnC .png" alt="" />
    </div>
    <ul>
      <li>
        1. This Campaign is organized by Sojitz Retail Management Sdn. Bhd.
        (the<i>“Organizer”</i>). All enquiries regarding the Campaign can be
        directed to marketing@sojitz-rm.com. The Campaign is conducted by the
        Organizer in collaboration with Tripellet Inc.(“TRPL”)
      </li>
      <li>
        2. Participation in this Campaign is deemed as an agreement to comply
        with the relevant terms, conditions, and regulations of this Campaign
        and any additional terms, conditions, and regulations announced by the
        Organizer and/orTRPL.
      </li>
      <li>
        3. The Organizer reserves the right to amend contest mechanics and these
        terms and conditions as and when deemed necessary. The Organizer or its
        affiliates further reserves the right to change, amend, delete or add to
        these terms and conditions without prior notice at any time and the
        participants shall be bound by such changes.
      </li>
      <li><a href="javaScript:;">Campaign Period</a></li>
      <li>
        4. This Campaign will commence on 10 March 2024 to 31 March 2024 (“<i
          >Campaign Period</i
        >”).
      </li>
      <li><a href="javaScript:;">Eligibility & Registration</a></li>
      <li>
        5. All individual customers of the Organizer who have made a purchase at
        J’s Gate Dining during the Campaign Period and have been issued a valid
        receipt by J’s Gate Dining are required to complete the following steps
        during the Campaign Period to be eligible to participate the Campaign:
      </li>
      <li style="padding-left: 3em">
        <i>Step 1 –</i> Make a purchase at J’s Gate Dining and scan the QR code
        on the receipt to download the Travel Contents Application
        (“<i>APP</i>”), and register an account on the APP to obtain an
        individual account ID.<br />
        <i>Description –</i> Participants will need to provide necessary
        information such as email address, date of birth and nationality.<br />
        <i>Step 2 –</i> complete a questionnaire on the APP.<br />
        <i>Description –</i> Participants are to answer a few questions in the
        questionnaire correctly. Only participants who have answered the
        questions in the questionnaire correctly will be eligible for selection
        as a potential prize winner.
      </li>
      <li style="padding-left: 3em">
        The Organizer reserves the right to request for proof of purchase from
        any participants in order to ascertain eligibility for participation in
        the Campaign and to disqualify any participants if such proof is not
        provided to the Organizer within such timeframe as may be stipulated by
        the Organizer.
      </li>
      <li>
        6. Any entries containing inaccurate, incomplete or out-of-date
        information which result in the Organizer being unable to
        identify/contact the participants/winners shall be disqualified. The
        Organizer reserves the right to use any other method or medium that it
        deems fit at its sole and absolute discretion for the purpose of
        announcing the Campaign winners. Participants shall be required to give
        their contact details and any other details deemed necessary and
        relevant upon joining the Campaign.
      </li>
      <li>
        7. The Organizer reserves the right to reject any questionnaire entry at
        its sole and absolute discretion on the grounds that such entry is
        incomplete, inappropriate, offensive, defamatory, plagiarized, repugnant
        to good taste and/or sensibilities, insensitive or in any way
        inconsistent with the spirit and objectives of the contest, and/or
        contrary to the laws of Malaysia. Failure by any participant to comply
        with any rule or regulation or instruction of the Organizer of any
        method prescribed by the Organizer will result in the participant being
        disqualified automatically.
      </li>
      <li><a href="javaScript:;">Winner Selection and Notification</a></li>
      <li>
        8. The winners of this Campaign will be selected from the participants
        who have correctly answered the questionnaire, using Excel’s random
        extraction method based on their APP account IDs. Prize winners do not
        have to be present at the time or place of the prize draw.
      </li>
      <li>
        9. This selection process will be recorded and posted on J’s Gate
        Dining’s social media account pages.
      </li>
      <li>
        10. On 10 April 2024, the account ID of the winners will be announced on
        J’s Gate Dining’s social media and the Travel Contents’ homepage at
        https://www.tripellet.com/cp240301-01. The winners of the Campaign will
        be notified between 10 April 2024 to 17 April 2024 via email, to the APP
        Member Registration Email.
      </li>
      <li>
        11. Winners must provide correct information within the Campaign Period.
        Failure to do so within the deadline will be considered a waiver of the
        prize, and non-winners will not be individually notified.
      </li>
      <li>
        12. Participants are required to ensure that the email address used to
        register and create their individual account IDs on the APP (“<i
          >APP Member Registration Email</i
        >”) is accurate and valid as all notifications, updates, and further
        details regarding the Campaign will be sent to the APP Member
        Registration email.
      </li>
      <li>
        13. Participants acknowledge and agree for their APP account ID to be
        posted and made public on the internet for any announcement made by the
        Organizer.
      </li>
      <li><a href="javaScript:;">Categories of Prizes</a></li>
      <li style="margin-bottom: 0">
        14. Winning participants will be entitled to the following prizes
        depending on the order in which they are randomly selected:
      </li>
    </ul>
    <div class="tabBox">
      <el-table
        :cell-style="{ borderColor: '#0081ff  ' }"
        :header-cell-style="{ borderColor: '#0081ff ' }"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column prop="number" label="No." width="5%">
        </el-table-column>
        <el-table-column prop="categories" label="Categories" width="42%">
          <template slot-scope="scope">
            <div
              class="jntsBox"
              v-for="(item, index) in scope.row.categories"
              :key="index"
            >
              <p v-if="item.type == 1">{{ item.txt }}</p>
              <a target="_blank" v-else-if="item.type == 2" :href="item.txt">{{
                item.txt
              }}</a>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="Quantity" width="12%">
        </el-table-column>
        <el-table-column prop="format" label="Award Format" width="15%">
          <template slot-scope="scope">
            <div v-html="scope.row.format"></div>
          </template>
        </el-table-column>
        <el-table-column prop="expiry" label="Points Expiry" width="11%">
        </el-table-column>
        <el-table-column prop="ETicket" label="E-Ticket Expiry" width="12%">
        </el-table-column>
      </el-table>
    </div>
    <div class="tabBox-mb">
      <el-table
        v-for="(tabItem,tabIndex) in tableDataMbList"
        :key="tabIndex"
        :show-header="false"
        :cell-style="{ borderColor: '#0081ff  ' }"
        :header-cell-style="{ borderColor: '#0081ff ' }"
        :data="tabItem"
        border
        style="width: 100%"
      >
        <el-table-column prop="title" width="27.5%"> </el-table-column>
        <el-table-column prop="val" width="72.5%">
          <template slot-scope="scope">
            <div v-if="scope.row.val.type == 1">
              <div
                class="jntsBox"
                v-for="(item, index) in scope.row.val.data"
                :key="index"
              >
                <p v-if="item.type == 1">{{ item.txt }}</p>
                <a
                  target="_blank"
                  v-else-if="item.type == 2"
                  :href="item.txt"
                  >{{ item.txt }}</a
                >
              </div>
            </div>
            <div v-if="scope.row.val.type == 2">
              <div v-html="scope.row.val.data"></div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <ul>
      <li>
        15. For prize No. 1/4 above, an e-voucher/e-ticket for the prize will be
        sent to the winner’s APP Member Registration Email.
      </li>
      <li>
        16. For prizes No. 2-3 above, APP points equivalent to the relevant
        prize value will be credited to the winners’ accounts in the APP.
        Winners must redeem the prizes via the APP using the points awarded to
        them in their APP account according to the rules specified by the
        Organizer.
      </li>
      <li>
        17. Winners can check their points on the APP on 10th April 2024. The
        points earned by the winners will be valid for one (1) year from 10th
        April 2024. Winners must exchange the points for the prizes via the APP
        within the stipulated timeline, failing which the points and/or the
        prizes shall be deemed forfeited.
      </li>
      <li>
        18. The Organizer shall not be liable or responsible for any loss of or
        damage to the prizes (a) for prize No. 1/4, once the e-vouchers/e-ticket
        for the same have been sent to the winner’s APP Member Registration
        Email; and (b) for prizes No. 2-3 above, once the equivalent APP points
        in the table above have been credited to the winners’ accounts in the
        APP.
      </li>
      <li>
        19. All prizes of this Campaign are non-refundable, non-exchangeable,
        and cannot be converted to cash. The Organizer reserves the right at its
        sole and absolute discretion to change, modify or substitute any of the
        prizes or any part thereof with that of a similar value at any time
        without prior notice.
      </li>
      <li><a href="javaScript:; ">General Terms and Conditions </a></li>
      <li>
        20. If there is any loss, error, unidentifiable, or damage to data
        submitted, filled, or registered by participants due to reasons beyond
        the control of the Organizer, such as computer malfunctions, network
        issues, telephone disruptions, technical glitches, or other unforeseen
        circumstances, resulting in delays, losses, theft, non-delivery or
        mis-delivery of prizes, the Organizer will not be responsible for this.
      </li>
      <li>
        21. The Organizer shall have the right to request relevant information,
        documents or written proof of identification and all other information
        necessary for this Campaign.
      </li>
      <li>
        22. The Organizer shall have the right to conduct investigations to
        determine whether a participant’s participation in the Campaign involve
        any form of illegal and/or unlawful activities and/or international
        misconduct, such as creating fake accounts on the APP, infiltrating the
        Campaign’s system, interfering with the selection process or inviting
        dummy accounts. Participants engaging in such inappropriate behavior may
        have their prizes revoked, and their participation in the Campaign may
        be refused at any time at the discretion of the Organizer.
      </li>
      <li>
        23. If the prize winner is under 18 years old, in addition to a copy of
        the winner’s ID card (front and back), a copy of the legal guardian’s ID
        card (front and back) must be provided for the prize collection process
        and any necessary procedures (if the winner does not have an ID card,
        the household registration transcript may be used as a substitute).
      </li>
      <li>
        24. The Organizer reserves the right to modify, suspend or terminate
        this Campaign at any time and have the final interpretation authority.
        Any modification, suspension or termination of this Campaign by the
        Organizer shall not give rise to any claim by the participants or any
        other persons whatsoever against the Organizer. The Organizer further
        reserves the right to cancel, modify, suspend or delay the Campaign
        and/or the prizes in the event of unforeseen circumstances beyond its
        reasonable control and shall not be liable for any losses, damages,
        claims, or costs incurred by the participants as a result thereof.
      </li>
      <li>
        25. By participating in this Campaign, participants are expressly
        confirming their irrevocable agreement to all terms and conditions
        contained herein and the Organizer’s personal data protection notice
        available below, and hereby irrevocably consent to the processing of
        their personal data and sensitive personal data as defined in the
        Personal Data Protection Act 2010, such as (but not exhaustive) name,
        identification card, identification card number, nickname, gender,
        contact information, appearances, characterization, likeness, and any
        photographs, images, content, information and/or materials which may
        identify the participants, for the purposes specified in the Organizer’s
        personal data protection notice and relating to this Campaign, including
        but not limited to:- (a) communicate with the participants; (b) to
        identify the eligibility of the participants in this Campaign; (c) to
        enable public participation in this Campaign, including allow public to
        view, rate, review, and comment on the contest entries and winners; (d)
        to identify the winners and to announce the results of the Campaign in
        any medium that the Organizer and/or -TRPL deems fit at their sole and
        absolute discretion; (e) for verification and internal administrative
        purposes; (f) disclosure to third parties including -TRPL, information
        technology service providers, and other third parties specified in the
        Organizer’s personal data protection notice for the purposes stated
        herein and the Organizer’s personal data protection notice; (g) for
        exploitation, publicity, advertising, trading, marketing and/or
        promoting of the Organizer, TRPL, and this Campaign; (h) for the purpose
        of awarding the prizes; and (i) any other purposes connected thereto. By
        participating in this Campaign, participants confirm and agree that the
        consent they have given does not replace any other consent(s) which they
        already provided to the Organizer and are in addition to any rights the
        Organizer has by law in connection with the collection, use and
        distribution of the participants’ personal data and sensitive personal
        data.
      </li>
      <li>
        26. By participating in this Campaign, participants agree and
        acknowledge that the Organizer shall have the exclusive and perpetual
        right to use the participants and/or winners’ name, photographs and/or
        other related information for the purposes of advertising, marketing,
        promoting and/or publicizing the Campaign across all media, in its
        original or edited format. Participants hereby further agree and consent
        that the Organizer shall have the right to publish, broadcast, televise,
        distribute, use or otherwise reproduce the participants’ post(s) on
        social media in relation to this Campaign.
      </li>
      <li>
        27. The Organizer and TRPL will not be liable for any deaths, injury,
        accidents caused directly or indirectly in the course of, during and
        after this Campaign.
      </li>
      <li>
        28. By participating in this Campaign, participants further undertake to
        hold the Organizer, TRPL, and their respective parent company,
        affiliates, related corporations, subsidiaries, directors, officers,
        employees and agents (collectively, “<i>Indemnitees</i>”) harmless and
        keep the Indemnitees fully indemnified against any and all actions,
        claims, demands and/or proceedings brought against the Indemnitees and
        any and all claims, costs, damages, expenses, fines, losses, liabilities
        and/or penalties (including but not limited to legal costs) which the
        Indemnitees may incur or suffer, as a result, whether directly or
        indirectly, from:- (a) the breach of these terms and conditions by the
        participants and/or by anyone acting on behalf of the participants,
        instructions, and/or of such confirmation, particularly where the
        Indemnitees are provided with false, incomplete or inaccurate personal
        data and/or sensitive personal data, and/or where the participants’
        submission of entries/questionnaires infringes the rights and/or
        intellectual property rights of a third party and/or contrary to the
        laws of Malaysia; (b) the participation of the participants in this
        Campaign; and/or (c) the winners’ acceptance or use of any prizes or any
        part thereof.
      </li>
      <li>
        29. The terms and conditions of this Campaign shall be governed by the
        laws of Malaysia and subject to the exclusive jurisdiction of the courts
        of Malaysia.
      </li>
      <div class="zhonImgBox">
        <img src="../../assets/images/cp240201/pp.png" alt="" />
      </div>
      <li>
        <p>Privacy Policy (the “Policy”)</p>
        <p>
          All users’ privacy is our priority. In order to make all users to feel
          at ease in the course of enjoying services provided by Tripellet
          Inc.’s (the “Company”), please read carefully the content of this
          Policy. If you continue to browse our website (the “Website”) or
          mobile application (the “APP”), or to use our services (the
          “Services”), you will be deemed to have accepted all privacy policy of
          the Company.
        </p>
      </li>
      <li>
        <i>I. Scope of Applicability of the Policy</i>
        <p>
          IThe Policy will explain to you how the Company collect, process and
          use all the information provided by users that is personally
          identified or identifiable, when users are browsing the Website, APP
          or using each of the Services. If you do not consent to the
          collection, process and use by the Company of your personal data,
          please do not continue to use the Website, APP and Services.
        </p>
        <p>
          2.This Policy as well as the PERSONAL DATA USE AGREEMENT shall apply
          when users are browsing the Website, APP or using the Service.
        </p>
      </li>
      <li>
        <i>II.　Methods of Collection, Process and Use of Personal data</i>
        <p>
          For the purposes of company management, business operation, promotion
          activities, information management, database maintenance, notice
          service, statistical surveys and analyses (the “Purpose”) and in the
          scope permitted by related provisions of Personal Data Protection Act
          (the “PDP Act”) or any applicable laws, the Company may, following the
          principle of honest and trustworthy and to the extent reasonable and
          necessary, collect, process and use all users’ information by
          automatic or non-automatic using manners which comply with
          contemporary technology, including contact information, account
          information, images, location and device information (such as device
          locations, device serial numbers and device materials), payment and
          transaction details, usage history (such as browser history, search
          history, deletion history and any other records) and any other
          information provided to the Company by users.
        </p>
      </li>
      <li>
        <i>III.　Protection of Personal Data</i>
        <p>
          To ensure safety of the Website and APP, the Company will configure
          firewall to prevent the Website from malware, interference or illegal
          access. For providing users with safe protection measure, the Company
          will also install anti-virus software to avoid attacks by malicious
          computer virus; carry out periodic maintenance of its security
          equipment and protection measure; and duly and properly upgrade its
          information security measure.
        </p>
      </li>
      <li>
        <i>IV.　Third Party Website Disclaimer</i>
        <p>
          1.The Website and APP may show third parties’ website links. Users
          shall refer to third parties’ own privacy policy accessing their
          websites by clicking links on the Website or APP. Third parties should
          create its own privacy policy, the Company’s Policy will not in any
          event apply to any third parties’ websites.
        </p>
        <p>
          2.The Company has no obligation or right to oversee, and shall not be
          liable for, any content, security measure and anything else available
          on third parties’ websites. If you choose to access third parties’
          websites from Company’s Website or APP, you shall take all risks
          resulting therefrom.
        </p>
      </li>
      <li>
        <i>V.　Sharing Policy of Personal data </i>
        <p>1.With users’ consent;</p>
        <p>2.If expressly permitted by applicable laws;</p>
        <p>
          3.In order to cooperate in legal investigation or utilization with
          courts, prosecutors offices or any other governmental organizations;
          or
        </p>
        <p>
          4.For specific purposes of information management and business, the
          Company may provide users’ personal data to its contractors, business
          partners or any other recipients agreed by users.
        </p>
      </li>
      <li>
        <i>VI.　The Rights to Personal Data</i>
        <p>Users may exercise the following rights by request in writing:</p>
        <p>
          1.Inquire, request for a review or make duplications of users’ data,
          however the Company may charge necessary handling fees. Under either
          of the circumstances stated in the proviso to Article 10 of PDP Act,
          the Company may reject to respond such request of inquiry, reviewing
          or making duplications.
        </p>
        <p>2.Request to supplement or correct users’ data.</p>
        <p>
          3.Request discontinuance of collection, process or use of users’ data.
        </p>
        <p>4.Request to delete users’ data or links.</p>
      </li>
      <li>
        <i>VII.　Cookie Technology</i>
        <p>
          In order to get a better acquaintance with all users’ preferences and
          characters in respect of the Website and APP and to improve each of
          the Services, the Company may, through cookie technology, place
          cookies in users’ devices and access the same so that the server can
          identify users and remember users’ preferences about the Website and
          APP, such as locations, languages and personal identification
          information but limited to the content input by users, the Company
          will not access information or archives that are not provided by
          users. Thereafter when users visit the Website and App again, the
          Website and APP may identify users and present such information
          satisfied users’ preferences and needs according to cookie data. Users
          may choose not to accept the cookie if users disagree with the
          foregoing, but users’ rejection may prevent the Website and APP from
          performing certain functions.
        </p>
      </li>
      <li>
        <i>VIII.　Changes to The Policy</i>
        <p>
          The Company is entitled to add, delete or amend any part or all of
          this Policy at any time without obligation of advance announcement or
          notification. If users continue to use the Services after the content
          of this Policy changed, the users will be deemed to have accepted such
          changes.
        </p>
      </li>
      <li>
        <i>IX.　Q&A </i>
        <p>
          If users have any questions to company’s privacy policy, please send
          an inquiry to us via following email.
        </p>
        <p>E-mail: tc.cs@tripellet.com</p>
        <p>We will make every effort to assist.</p>
      </li>
      <li></li>
    </ul>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          number: "1",
          categories: [
            { type: 1, txt: "Airasia X Return Flights" },
            { type: 1, txt: "to Osaka from Kuala Lumpur" },
            { type: 2, txt: "https://www.airasia.com/flights/malaysia" },
            { type: 1, txt: "For reservation, please visit" },
            { type: 2, txt: "https://support.airasia.com/s/?language=en_GB" },
          ],
          quantity: "2 winners",
          format: "e-voucher <br> (sent in email)",
          expiry: "-",
          ETicket: "01 Sept 2024",
        },
        {
          number: "2",
          categories: [
            { type: 1, txt: "Universal Studios Japan 1 Day Studio Pass " },
            { type: 2, txt: "https://www.usj.co.jp/web/en/us" },
          ],
          quantity: "20 winners",
          format: "9900 APP points",
          expiry: "9 April 2025",
          ETicket: "After 3 months after issued ",
        },
        {
          number: "3",
          categories: [
            { type: 1, txt: "“Have Fun in Kansai” pass" },
            {
              type: 2,
              txt: "https://www.tripellet.com/hfPage?id=HFKansai&l=en",
            },
          ],
          quantity: "50  winners",
          format: "3000 APP points ",
          expiry: "9 April 2025",
          ETicket: "After 90 days after issued",
        },
        {
          number: "4",
          categories: [
            { type: 1, txt: "Kansai Hokuriku + Fukui 1 Week Free  Pass" },
            {
              type: 2,
              txt: "https://www.westjr.co.jp/global/en/ticket/pass/kansai_hokuriku",
            },
            { type: 2, txt: "https://www.tripellet.com/hffukui/en" },
          ],
          quantity: "10  winners",
          format: "e-ticket <br> (sent in email)	",
          expiry: "-",
          ETicket: "7 July 2024",
        },
      ],
      tableDataMbList: [
        // [
        //   {
        //     title: "Categories1",
        //     val: {
        //       type: 1,
        //       data: [
        //         { type: 1, txt: "Airasia X Return Flights" },
        //         { type: 1, txt: "to Osaka from Kuala Lumpur" },
        //         { type: 2, txt: "https://www.airasia.com/flights/malaysia" },
        //         { type: 1, txt: "For reservation, please visit" },
        //         {
        //           type: 2,
        //           txt: "https://support.airasia.com/s/?language=en_GB",
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     title: "Quantity",
        //     val: { type: 2, data: "2 winners" },
        //   },
        //   {
        //     title: "Award Format",
        //     val: { type: 2, data: "e-voucher <br> (sent in email)" },
        //   },
        //   {
        //     title: "Points Expiry",
        //     val: { type: 2, data: "-" },
        //   },
        //   {
        //     title: "E-Ticket Expiry",
        //     val: { type: 2, data: "01 Sept 2024" },
        //   },
        // ],
        // [
        //   {
        //     title: "Categories2",
        //     val: {
        //       type: 1,
        //       data: [
        //         { type: 1, txt: "Airasia X Return Flights" },
        //         { type: 1, txt: "to Osaka from Kuala Lumpur" },
        //         { type: 2, txt: "https://www.airasia.com/flights/malaysia" },
        //         { type: 1, txt: "For reservation, please visit" },
        //         {
        //           type: 2,
        //           txt: "https://support.airasia.com/s/?language=en_GB",
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     title: "Quantity",
        //     val: { type: 2, data: "2 winners" },
        //   },
        //   {
        //     title: "Award Format",
        //     val: { type: 2, data: "e-voucher <br> (sent in email)" },
        //   },
        //   {
        //     title: "Points Expiry",
        //     val: { type: 2, data: "-" },
        //   },
        //   {
        //     title: "E-Ticket Expiry",
        //     val: { type: 2, data: "01 Sept 2024" },
        //   },
        // ],
      ],
    };
  },
  created () {
    for (const key in this.tableData) {
      console.log(this.tableData[key]);
      let newArr = [
          {
            title: "Categories" + (+key + 1),
            val: {
              type: 1,
              data: this.tableData[key].categories,
            },
          },
            {
            title: "Quantity",
            val: { type: 2, data: this.tableData[key].quantity },
          },
          {
            title: "Award Format",
            val: { type: 2, data: this.tableData[key].format },
          },
          {
            title: "Points Expiry",
            val: { type: 2, data: this.tableData[key].expiry },
          },
          {
            title: "E-Ticket Expiry",
            val: { type: 2, data: this.tableData[key].ETicket },
          },
      ]
      
      this.tableDataMbList.push(newArr)
    }
  }
};
</script>

<style lang="scss" scoped>
.m {
  padding:0 3.2vw 54px;
  // width: 24vw;
  font-size: 11px;
  color: #000;
  max-width: 800px;
  margin: 0 auto;
}
a {
  font-weight: 700;
  color: #000;
  text-decoration: underline;
}
i {
  font-weight: 700;
}
ul {
  li {
    margin-bottom: 18px;
    line-height: 14px;
  }
}
.addTitImgBox {
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}

.addTitImgBox-mb {
  display: none;
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}
.titImgBox {
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 73%;
    max-width: 504px;
  }
}
.zhonImgBox {
  text-align: center;
  margin: 45px 0 26px;
  img {
    width: 288px;
  }
}
/deep/.tabBox,
/deep/.tabBox-mb {
  margin: 16px 0;
  .el-table {
    border: solid 1px #0081ff;
  }
  .el-table td.el-table__cell div {
    line-height: 16px;
    a {
      word-break: break-all;
      color: #0081ff;
      font-weight: 400;
    }
  }
  .el-table th.el-table__cell > .cell {
    text-align: center;
    color: #000;
    word-break: unset;
  }
  .el-table--border .el-table__cell:first-child .cell {
    text-align: center;
    word-break: unset;
    padding: 0;
  }
  .el-table td.el-table__cell div {
    text-align: center;
    word-break: unset;
    color: #000;
    .jntsBox {
      text-align: left;
    }
  }
}
/deep/.el-table__header {
  width: 100% !important;
  font-size: 12px;
}
/deep/.el-table__body {
  width: 100% !important;
  font-size: 12px;
}

.tabBox-mb {
  display: none;
}

@media (max-width: 800px) {
  .addTitImgBox{
    display: none;
  }
  .addTitImgBox-mb{
    display: block;
  }
  .tabBox {
    display: none;
  }
  /deep/.tabBox-mb {
    display: block;
    .el-table{
      margin-bottom: 11px;
.el-table__cell {
      padding: 8px 0;
    }

    td.el-table__cell div{
      text-align: left;
    }
    }
  }
}
</style>
